/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Heading } from '@datacamp/waffles/heading';
import { mediaQuery } from '@datacamp/waffles/helpers';
import { useMediaQuery } from '@datacamp/waffles/hooks';
import { ArrowRight } from '@datacamp/waffles/icon';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DailyStreakScreenWrapper from './DailyStreakScreenWrapper';
import { DoubleFreezeIcon } from './FreezeIcon';

const DailyStreakFreezesScreen: React.FC<{ onExit: () => void }> = (props) => {
  const { t } = useTranslation();

  const { onExit } = props;

  const autoFocusRef = useRef(null);
  const { isAboveSmall } = useMediaQuery();

  return (
    <DailyStreakScreenWrapper initialFocus={autoFocusRef}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          [mediaQuery.aboveSmall]: { flexGrow: 0 },
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <DoubleFreezeIcon />
        <Heading
          css={{
            fontSize: '32px',
          }}
          data-testid="freezes-awarded-header"
        >
          {t('DailyStreakModal.freezeOwnedHeader')}
        </Heading>
        <Paragraph
          css={{
            marginBottom: tokens.spacing.xlarge,
            fontSize: tokens.fontSizes.medium,
          }}
        >
          {t('DailyStreakModal.freezeAwardedMessage')}
        </Paragraph>
      </div>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexWrap: 'wrap',
          gap: tokens.spacing.medium,
          position: 'relative',
        }}
      >
        <Button
          ref={autoFocusRef}
          variant="cta"
          iconRight={isAboveSmall ? <ArrowRight /> : undefined}
          fullWidth={!isAboveSmall}
          onClick={onExit}
        >
          {t('DailyStreakModal.cta')}
        </Button>
      </div>
    </DailyStreakScreenWrapper>
  );
};

export default DailyStreakFreezesScreen;
