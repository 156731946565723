/** @jsx jsx */
import { Heading } from '@datacamp/waffles/heading';
import { mediaQuery } from '@datacamp/waffles/helpers';
import { Checkmark } from '@datacamp/waffles/icon';
import { darkThemeStyle } from '@datacamp/waffles/theme';
import { tokens } from '@datacamp/waffles/tokens';
import { css, jsx } from '@emotion/react';
import { useLottie } from 'lottie-react';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SnowflakeIcon from '../../assets/images/Snowflake.svg';
import type { StreakWeekItem } from '../../redux/reducers/streaks/types';

import shimmer from './animations/shimmer.json';

const FlashShimmer = ({ width }: { width: string }) => {
  const { View, setSpeed } = useLottie(
    {
      animationData: shimmer,
      autoplay: true,
      initialSegment: [0, 300],
      loop: true,
    },
    { width },
  );

  useEffect(() => {
    setSpeed(2);
  }, [setSpeed]);
  return View;
};

const getMobileSizes = ({ days }: { days: number }) => {
  return days < 1000
    ? { flash: '92px', font: '120px' }
    : { flash: '78px', font: '100px' };
};

const mapStatusToColor = (status: 'frozen' | 'met' | 'unmet'): string => {
  switch (status) {
    case 'met':
      return tokens.colors.yellow;
    case 'frozen':
      return tokens.colors.blueDark;
    case 'unmet':
    default:
      return tokens.colors.navyLight;
  }
};

const mapStatusToIcon = (status: 'frozen' | 'met' | 'unmet'): ReactNode => {
  switch (status) {
    case 'met':
      return <Checkmark />;
    case 'frozen':
      return <img src={SnowflakeIcon} alt="" />;
    case 'unmet':
    default:
      return <></>;
  }
};

const StreakDay = ({
  day,
  status,
  today = false,
}: {
  day: string;
  status: 'frozen' | 'met' | 'unmet';
  today?: boolean;
}): JSX.Element => (
  <div
    css={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      gap: tokens.spacing.xsmall,
    }}
  >
    <div
      css={{
        color: today
          ? tokens.colors.greyLight
          : tokens.colors.navySubtleTextOnLight,
      }}
    >
      {day[0]}
    </div>
    <div
      css={{
        alignItems: 'center',
        background: mapStatusToColor(status),
        border: `${tokens.borderWidth.medium} solid ${
          today ? tokens.colors.navySubtleTextOnLight : tokens.colors.navyMedium
        }`,
        borderRadius: tokens.borderRadius.circle,
        display: 'flex',
        height: tokens.sizing.small,
        justifyContent: 'center',
        margin: `0 2px`,
        width: tokens.sizing.small,
      }}
    >
      {mapStatusToIcon(status)}
    </div>
  </div>
);

type StreakWidgetProps = {
  isMobile: boolean;
  streakLengthInDays: number;
  streakWeekView: StreakWeekItem[];
};

export const StreakWidget: React.FC<StreakWidgetProps> = ({
  isMobile,
  streakLengthInDays,
  streakWeekView,
}) => {
  const { t } = useTranslation();

  const [isStreakAnimationFinished, setIsStreakAnimationFinished] = useState(
    false,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsStreakAnimationFinished(true);
    }, 800); // initial delay before the flip starts

    return () => clearTimeout(timer);
  }, [streakLengthInDays]);

  // Sunday is 0, we want it to be 6
  const todayIndex = (new Date().getDay() - 1 + 7) % 7;

  const days = streakWeekView.map((day) => {
    return t(`StreakWidget.weekdayLabel${day.weekday}`);
  });

  const flashDimension = isMobile
    ? getMobileSizes({ days: streakLengthInDays }).flash
    : '106px';

  return (
    <div
      css={[
        darkThemeStyle,
        css({
          borderRadius: '8px',
          border: `${tokens.borderWidth.medium} solid ${tokens.colors.navySubtleTextOnLight}`,
        }),
      ]}
    >
      <div
        css={{
          alignItems: 'center',
          color: tokens.colors.yellow,
          display: 'flex',
          flexWrap: 'nowrap',
          gap: tokens.spacing.small,
          justifyContent: 'center',
          padding: isMobile ? tokens.spacing.small : tokens.spacing.medium,
          textAlign: 'center',
          opacity: isStreakAnimationFinished ? 1 : 0.4,
          transition: 'opacity 0.5s',
        }}
      >
        <FlashShimmer width={flashDimension} />

        <div
          css={{
            perspective: '1000px',
          }}
        >
          <div
            css={{
              position: 'relative',
              width: '60px',
              height: '100px',
              transformStyle: 'preserve-3d',
              transition: 'transform 0.5s',
              transform: isStreakAnimationFinished
                ? 'rotateX(0deg)'
                : 'rotateX(-180deg)',
            }}
          >
            <Heading
              css={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                color: tokens.colors.greyLight,
                fontSize: isMobile
                  ? getMobileSizes({ days: streakLengthInDays }).font
                  : '140px',
                backfaceVisibility: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {streakLengthInDays}
            </Heading>

            <Heading
              css={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                color: tokens.colors.greyLight,
                fontSize: isMobile
                  ? getMobileSizes({ days: streakLengthInDays }).font
                  : '140px',
                backfaceVisibility: 'hidden',
                transform: 'rotateX(180deg)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {streakLengthInDays - 1}
            </Heading>
          </div>
        </div>
      </div>
      <div
        css={{
          background: tokens.colors.navyMedium,
          borderRadius: '0 0 8px 8px',
          display: 'flex',
          flexWrap: 'nowrap',
          gap: tokens.spacing.small,
          justifyContent: 'center',
          [mediaQuery.aboveSmall]: {
            gap: tokens.spacing.medium,
          },
          padding: isMobile ? tokens.spacing.medium : tokens.spacing.large,
          paddingTop: tokens.spacing.medium,
        }}
      >
        {days.map((day, index) => (
          <StreakDay
            day={day}
            key={index}
            status={streakWeekView[index].status}
            today={todayIndex === index}
          />
        ))}
      </div>
    </div>
  );
};
