import React, { useCallback, useEffect, useMemo, useState } from 'react';

import config from '../../config';
import { isFeatureEnabled } from '../../helpers/featureFlags';
import { useDispatch, useSelector } from '../../interfaces/State';
import { hideDailyStreakScreen } from '../../redux/actions';
import {
  selectDailyStreakScreenVisible,
  selectStreakDays,
} from '../../redux/selectors';
import * as selectors from '../../redux/selectors';

import DailyStreakAppScreen from './DailyStreakAppScreen';
import DailyStreakFreezesScreen from './DailyStreakFreezesScreen';
import DailyStreakScreen from './DailyStreakScreen';
import { isStreakMilestone } from './isStreakMilestone';

const DailyStreakScreenHandler: React.FC = () => {
  const dispatch = useDispatch();

  const userId = useSelector(selectors.selectUserSettings).get('id');
  const streakDays = useSelector(selectStreakDays);
  const show = useSelector(selectDailyStreakScreenVisible);

  const [screen, setScreen] = useState<
    'DAILY_STREAK' | 'FREEZES_AWARDED' | 'MOBILE_APP'
  >('DAILY_STREAK');

  const hasFreezeAttributionEnabled = useMemo(() => {
    if (!show) {
      return false;
    }

    const hasStreakLogicEnabled = isFeatureEnabled('wl--streak-freezes');

    if (!hasStreakLogicEnabled) {
      return false;
    }

    return isFeatureEnabled('wl--streak-freeze-attribution');
  }, [show]);

  const onExit = useCallback(() => {
    const shouldShowFreezeModal =
      hasFreezeAttributionEnabled &&
      streakDays !== undefined &&
      (streakDays === 2 || streakDays % 5 === 0);

    // TODO: check if mobile app screen needs to be shown
    const shouldShowMobileScreen = false;

    function finish() {
      dispatch(hideDailyStreakScreen());
      setScreen('DAILY_STREAK');
    }

    switch (screen) {
      case 'DAILY_STREAK': {
        if (shouldShowFreezeModal) {
          setScreen('FREEZES_AWARDED');
        } else if (shouldShowMobileScreen) {
          setScreen('MOBILE_APP');
        } else {
          finish();
        }
        break;
      }
      case 'FREEZES_AWARDED': {
        if (shouldShowMobileScreen) {
          setScreen('MOBILE_APP');
        } else {
          finish();
        }
        break;
      }
      case 'MOBILE_APP':
      default:
        finish();
        break;
    }
  }, [dispatch, hasFreezeAttributionEnabled, screen, streakDays]);

  useEffect(() => {
    if (!show || !window.dataLayer) {
      return;
    }

    window.dataLayer.push({
      event: 'heap_event',
      heap_event_name: 'Learn - Campus - Streak Completed',
      heap_event_properties: {
        app_id: config.appName,
        event_category: 'learning',
        identity: userId,
        isMilestone: isStreakMilestone(streakDays ?? 0),
        streakDays,
      },
    });
  }, [show, streakDays, userId]);

  if (!show) {
    return null;
  }

  if (screen === 'MOBILE_APP') {
    return <DailyStreakAppScreen onExit={onExit} />;
  }

  if (screen === 'FREEZES_AWARDED') {
    return <DailyStreakFreezesScreen onExit={onExit} />;
  }

  return <DailyStreakScreen onExit={onExit} />;
};

export default DailyStreakScreenHandler;
